export const FAIRLEAD_LABEL = 'Fairleads'
export const WINCH_LABEL = 'Winches'
export const PEDESTAL_LABEL = 'Pedestals'
export const TAULINE_LABEL = 'Mooring lines'
export const HIDDEN_LABEL = 'Hidden'

export const CONTOUR_COLOR = '#B80000'
export const FAIRLEAD_COLOR = '#4F81BD'
export const WINCH_COLOR = '#F79646'
export const PEDESTAL_COLOR = '#3A870E'
export const LINE_COLOR = '#000000'
