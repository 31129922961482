import { defineStore } from 'pinia'
import type {
  DocumentStateType,
  DocumentType,
  PureDocumentType
} from '@/stores/type/pureDocumentType'
import { useShipStore } from '@/stores/ship'
import API from '@/api/apiUrls'
import AppBaseStore from '@/stores/baseStore'
import { useDjangoAuthStore } from '@/stores/authDjango'
import type { ShipType } from '@/stores/type/ship.type'
import { useApi } from '@/api/useApi'
import { useEventsBus } from '@rhdhv/vue-component-library'

const { api } = useApi()

const baseUrl = API.DOCUMENT.BASE
const baseStore = new AppBaseStore<PureDocumentType>(baseUrl, api)

export const useDocumentStore = defineStore('document', {
  state: (): DocumentStateType => ({
    ...baseStore.appState,
    documentItems: [],
    uploadLoading: false,
    currentUploadIndex: null,
    downloadLoading: false
  }),
  actions: {
    ...baseStore.appActions,
    async uploadDocumentFiles(shipId: number): Promise<void> {
      this.uploadLoading = true
      const unUploadedItems = this.documentItems.filter((item) => !item.sent)
      for (const item of unUploadedItems) {
        await this.uploadDocumentFile(item.file, shipId)
      }
      this.uploadLoading = false
    },
    setDocuments(documents: PureDocumentType[]): void {
      this.documentItems = documents.map((document) => ({
        ...document,
        failed: false,
        loading: false,
        sent: true
      }))
    },
    resetDocuments(): void {
      this.documentItems = []
    },
    clearUnsavedDocuments(): void {
      this.documentItems = this.documentItems.filter((item) => item.sent && !item.failed)
    },
    addFiles(files: File[]): void {
      const uploadFiles: DocumentType[] = files.map((file) => {
        return { file, failed: false, loading: false, sent: false, file_name: file.name }
      })
      this.documentItems.unshift(...uploadFiles)
    },
    removeUnsentFiles(): void {
      this.documentItems = this.documentItems.filter((item) => item.sent)
    },
    async uploadDocumentFile(file: File, shipId: number): Promise<boolean> {
      const index = this.documentItems.findIndex((item) => item.file === file)
      if (index !== -1) {
        const item = this.documentItems[index]
        try {
          item.loading = true
          const url = baseUrl + 'upload/'
          const formData = new FormData()
          formData.append('ship', shipId.toString())
          formData.append('file', item.file)
          const response = await api.service(url).post(formData)
          item.sent = true
          item.response = response as PureDocumentType
          item.id = response.id
          item.file_name = response.file_name

          const { emit } = useEventsBus()
          const uploadMessage = {
            status: 200,
            data: [],
            method: 'POST',
            statusText: 'Uploaded document!'
          }
          emit('success', uploadMessage)

          item.loading = false
          return true
        } catch {
          item.failed = true
          item.loading = false
          item.sent = true
          return false
        }
      }
      return false
    },
    async downloadDocument(id: number): Promise<void> {
      this.downloadLoading = true
      const url = API.DOCUMENT.DOWNLOAD(id)
      const shipStore = useShipStore()
      await shipStore.download(url)
      this.downloadLoading = false
    }
  },
  getters: {
    documentPermissions:
      () =>
      (ship: ShipType | null): boolean => {
        if (!ship) return false
        const djangoAuthStore = useDjangoAuthStore()
        const isNotApproved = ship.validation_set?.every((approval) => !approval.approved) ?? false
        return (
          djangoAuthStore.user?.is_superuser ||
          (ship.user === djangoAuthStore.user?.id && isNotApproved)
        )
      }
  }
})
