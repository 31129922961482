<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useDocumentStore } from '@/stores/document'
import DocumentTableRow from '@/components/document/DocumentTableRow.vue'

const props = defineProps({
  tableHeight: { type: Number, default: 20 },
  addDocument: { type: Boolean, default: false },
  addLoading: { type: Boolean, default: false },
  addDisabled: { type: Boolean, default: false }
})

const emit = defineEmits(['add'])

const { documentItems } = storeToRefs(useDocumentStore())

function removeDocument(index: number) {
  documentItems.value.splice(index, 1)
}
</script>

<template>
  <v-table :height="tableHeight + 'vh'" class="overflow-auto" density="compact" fixed-header hover>
    <thead>
      <tr>
        <th class="text-center border-right-grey" style="width: 70px">Status</th>
        <th class="text-center">Document</th>
        <th class="px-1" style="width: 1%">
          <v-btn
            v-if="addDocument"
            :disabled="addDisabled"
            prepend-icon="mdi-plus"
            color="primary"
            variant="tonal"
            class="text-none"
            @click="emit('add')"
          >
            Add
          </v-btn>
        </th>
      </tr>
    </thead>
    <tbody>
      <DocumentTableRow
        v-for="(item, index) in documentItems"
        :key="index"
        :document="item"
        @remove="removeDocument(index)"
      ></DocumentTableRow>
      <tr v-if="!documentItems.length">
        <td colspan="3" style="height: 40px; text-align: center">No document</td>
      </tr>
      <tr>
        <td colspan="48" style="height: 3px"></td>
      </tr>
    </tbody>
  </v-table>
</template>
