<script setup lang="ts">
import router from '@/router'

import ToolBar from '@/components/app/ToolBar.vue'
import { DocumentationButton, RHDHVHeaderLogo, RHDHVHeaderMenu } from '@rhdhv/vue-component-library'
import { useDjangoAuthStore } from '@/stores/authDjango'

const authStore = useDjangoAuthStore()
const { user } = storeToRefs(authStore)

const goToProjectIndex = () => {
  router.push({ name: 'project-index' })
}

async function logout() {
  await authStore.logout()
  authStore.$patch({
    user: null
  })
  await router.push({ name: 'auth-login' })
}
</script>

<template>
  <v-app-bar fixed app color="white" dark class="pa-0" extension-height="40">
    <RHDHVHeaderLogo
      @logo-click="goToProjectIndex"
      height="60"
      width="120"
      image-src="/rhdhv-logo.jpg"
    />
    <v-spacer></v-spacer>
    <DocumentationButton />
    <RHDHVHeaderMenu v-if="user" :user="user" @logout="logout()" color="#a9a9a9"></RHDHVHeaderMenu>
    <template #extension>
      <ToolBar />
    </template>
  </v-app-bar>
</template>
