const baseURL = import.meta.env.VITE_APP_API_BASE_URL

const API = {
  TOKEN: {
    ACCESS: `${baseURL}api/token/`,
    REFRESH: `${baseURL}api/token/refresh/`
  },
  APPLICATION: {
    BASE: `${baseURL}applications/`
  },
  DOCUMENT: {
    BASE: `${baseURL}documents/`,
    DOWNLOAD: (id: number) => `${baseURL}documents/${id}/download/`
  },
  VALIDATION: {
    BASE: `${baseURL}validations/`
  },
  FAIRLEAD: {
    BASE: `${baseURL}fairleads/`
  },
  SHIP: {
    BASE: `${baseURL}ships/`,
    PAGINATE: `${baseURL}ships_pagination/`
  },
  SHIP_TYPE: {
    BASE: `${baseURL}ship_types/`
  },
  USER: {
    BASE: `${baseURL}users/`,
    CURRENT_USER: `${baseURL}users/current_user/`,
    RHDHV_AUTH: `${baseURL}oauth/azure/`
  }
}

export default API
